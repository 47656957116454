<template>
  <form-wizard-number />
</template>

<script>
import FormWizardNumber from '@/views/forms/form-wizard/FormWizardNumber'

export default {

  name: 'VerifiedVue',
  components: {
    FormWizardNumber,
  },
  data() {
    return {

      user: [],

    }
  },

  mounted() {
    const x = localStorage.getItem('userData')
    this.user = JSON.parse(x)
    // console.log(x)
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
